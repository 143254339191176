import Vuex from 'vuex';
import {
  getProjectConfig,
  request,
} from '@/utils/utils';
import { faqMap } from './AppsLang';
import def from './default.json';

// 说明内容配置
const getFaqsContent = ({ platform, langFlag }) => (
  faqMap()[langFlag][platform] || console.error(`平台为${platform}, 语言为${langFlag}的说明书不存在`)
);

// 说明内容配置及映射
const getFaqsMessage = (tag, isMacInstaller, isSuperSign) => {
  const o = {
    // ios1: { showFap: true, platform: 'ios' },
    // ios2: { showFap: false, platform: '' },
    android1: { showFap: true, platform: 'android' },
    android2: { showFap: true, platform: 'android' },
    windows1: { showFap: true, platform: 'win' },
    windows2: { showFap: true, platform: 'win' },
    mac1: { showFap: true, platform: 'mac' },
    mac2: { showFap: true, platform: 'mac' },
    ubuntu1: { showFap: true, platform: 'linux' },
    ubuntu2: { showFap: false, platform: '' },
  };
  if (tag === 'mac1' && isMacInstaller) {
    return { showFap: true, platform: 'mac_installer' };
  }
  if (tag === 'ios1' && isSuperSign) {
    return { showFap: true, platform: 'super_sign' };
  }
  return o[tag] || console.error(`${tag}不存在`);
};

// 获取当前要展示的说明内容
const getFaqs = ({
  url,
  tag,
  langFlag,
  isMacInstaller,
  isSuperSign,
}) => {
  const { showFap, platform } = getFaqsMessage(tag, isMacInstaller, isSuperSign);
  if (!showFap) return [showFap, []];
  let faqs = getFaqsContent({ platform, langFlag });
  if (platform === 'ios') {
    let certName = 'HongKong IRay..."';
    if (url.indexOf('quickq.plist') === -1) {
      certName = 'VO NI TPU, TPU, NAT...';
    }
    faqs = faqs.map(item => ({ ...item, value: item.value.replace('``', `\`${certName}\``) }));
  }
  return new Promise(reslove => {
    setTimeout(() => {
      reslove([showFap, faqs]);
    }, 500);
  });
};

const defaultDownloadList = {
  // ios1: {},
  // ios2: {},
  android1: {},
  android2: {},
  windows1: {},
  windows2: {},
  mac1: {},
  mac2: {},
  ubuntu1: {},
  ubuntu2: {},
};

export const createState = () => new Vuex.Store({
  state: {
    langFlag: '',
    isMd: false,
    content: {},
    code: '',
    agent: '',
    data: defaultDownloadList,
    show: false,
    faqs: [],
  },
  mutations: {
    langFlag(state, langFlag) {
      state.langFlag = langFlag;
    },
    isMd(state, isMd) {
      state.isMd = isMd;
    },
    content(state, content) {
      state.content = content;
    },
    code(state, code = '') {
      state.code = code;
    },
    agent(state, agent = '') {
      state.agent = agent;
    },
    data(state, data) {
      state.data = { ...state.data, ...data };
    },
    show(state, show) {
      state.show = show;
    },
    faqs(state, faqs) {
      state.faqs = faqs;
    },
  },
  actions: {
    async request(ctx, {
      code,
      agentId,
    }) {
      // eslint-disable-next-line prefer-const
      let [isSuccess, res] = await request({
        url: '/download/list',
        query: {
          agent_id: agentId,
        },
      });
      if (!isSuccess) {
        res = { data: def };
      }
      ctx.commit('code', code);
      ctx.commit('agent', agentId);
      ctx.commit('data', res.data.data);
    },
    async showFaqs(ctx, { tag, link }) {
      const [show, faqs] = await getFaqs({
        tag,
        url: link || ctx.state.data[tag].link,
        langFlag: ctx.state.langFlag,
        isMacInstaller: ctx.getters.isMacInstaller,
        isSuperSign: ctx.getters.isSuperSign,
      });
      ctx.commit('show', show);
      ctx.commit('faqs', faqs);
    },
  },
  getters: {
    isMacInstaller(state) {
      return !(
        state.data.mac1
        && state.data.mac1.link
        && state.data.mac2
        && state.data.mac2.link
      );
    },
    isSuperSign(state) {
      return !state.data.ios1?.link?.startsWith('itms-services');
    },
    appList(state, getters) {
      const {
        isMd,
        content,
        langFlag,
        data,
      } = state;
      const { isMacInstaller } = getters;
      const {
        text2,
        text3,
        text4,
        text5,
        text14,
        text10,
        text17,
        text13,
        text19,
        text21,
        text25,
        text26,
        text27,
        text28,
        text29
      } = content;
      const { imgSrcBase } = getProjectConfig();
      return [
        // {
        //   key: 'ios',
        //   title: 'iPhone/iPad',
        //   support: text2,
        //   btns: [
        //     { img: `${imgSrcBase}/imgs/download/download_btn1.png`, desc: text17, download: 'ios2' },
        //     { label: text21, desc: text17, download: 'ios1' },
        //   ],
        // },
        {
          key: 'android',
          title: 'Android',
          support: text3,
          btns: [
            { label: text10, download: 'android1' },
            // { img: `${imgSrcBase}/imgs/download/download_btn2.png`, download: 'android2' },
          ],
        },
        {
          key: 'windows',
          title: 'Windows',
          support: text4,
          btns: [
            { label: text25, download: 'windows1', hidden: !isMd },
            {
              label: text13,
              // isBlackBtn: true,
              download: 'windows2',
              hidden: !isMd,
            },
          ],
        },
        {
          key: 'mac',
          title: 'Mac',
          support: isMacInstaller ? text19 : text5,
          btns: [
            isMacInstaller && {
              label: text10,
              download: 'mac1',
              hidden: !isMd,
            },
            !isMacInstaller && {
              label: text26,
              // isSelect: true,
              download: 'mac1',
              hidden: !isMd,
            },
            !isMacInstaller && {
              label: text27,
              // isSelect: true,
              download: 'mac2',
              hidden: !isMd,
            },
          ],
        },
        {
          key: 'ubuntu',
          title: 'Linux',
          support: text14,
          btns: [
            { label: text29, download: 'ubuntu1', hidden: !isMd },
            { label: text28, download: 'ubuntu2', hidden: !isMd },
          ],
        },
      ]
        .map(item => ({
          ...item,
          btns: item.btns.filter(({ download }) => data[download]?.link !== undefined).filter(({ download }) => download !== 'ios1'),
        }))
        .map(item => ({
          ...item,
          version: state.data[`${item.key}1`].version ?? state.data[`${item.key}2`].version,
          poster: `${imgSrcBase}/imgs/download/download_${item.key}_${langFlag}.png`,
        }));
    },
  },
});
