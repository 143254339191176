import {
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import axios from 'axios';
import {
  operateLog,
  openNewPage,
  showSelectList,
  getPlatform,
  getAliyun,
  isCanDownloadBrowser,
  showOpenOthetBrowserTips,
  showConfirm,
  showLoading,
  pageSaveItem,
} from '@/utils/utils';
import {
  useContent,
  useHeaderType,
  useRoute,
  useStore,
  useRouter,
  useIsMd,
  useInitCodeAndAgent,
  useAutoLogin,
} from '@/utils/hooks';
import { createState } from './AppsState';
import { lang } from './AppsLang';

/**
 * @param {*} tag 下载目标枚举值
 * @returns 下载目标配置信息
 */
const getDownloadContent = tag => {
  const o = {
    // ios1: { log: 'IOS_DOWNLOAD', platform: 'ios' },
    // ios2: { log: 'APPSTORE_GO', platform: '' },
    android1: { log: 'ANDROID_DOWNLOAD', platform: 'android' },
    android2: { log: 'GOOGLEPLAY_DOWNLOAD', platform: 'android' },
    windows1: { log: 'WINDOWS_DOWNLOAD', platform: 'win' },
    windows2: { log: 'WINDOWS_OFFLINE_DOWNLOAD', platform: 'win' },
    mac1: { log: 'MACOS_DMG', platform: 'mac' },
    mac2: { log: 'MACOS_ZIP', platform: 'mac' },
    ubuntu1: { log: 'LINUX_DEB', platform: 'linux' },
    ubuntu2: { log: 'LINUX_ZIP', platform: '' },
  };
  return o[tag] || console.error(`${tag}不存在`);
};

/**
 * @param {*} params 从其他页面底部点击Apps图标跳转到该页面时携带值，当前要显示哪个下载模块
 * @returns 平台对应的枚举值
 */
const getSwitchActive = ({ switchActive }) => {
  if (switchActive) return switchActive;
  const platform = getPlatform();
  const o = {
    ios: 1,
    ipad: 1,
    iphone: 1,
    android: 1,
    windows: 2,
    mac: 3,
    linux: 4,
  };
  // 以上都不是, 则是未记录的设备系统
  return o[platform] || 2;
};

export const useApps = () => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const isMd = useIsMd();
  const content = useContent(lang());
  const x = createState();

  watch([store.state, isMd, content], ([v1, v2, v3]) => {
    x.commit('langFlag', v1.langFlag);
    x.commit('isMd', v2);
    x.commit('content', v3);
  }, { immediate: true });

  useInitCodeAndAgent(async ({ code, agentId }) => {
    if (code) {
      try {
        const { p, v } = route.query;
        getAliyun({
          url: '/logstores/web_tracking_log/track',
          query: {
            APIVersion: '0.6.0',
            __topic__: 'SHARE_PAGE',
            vcode: code,
            p,
            v,
          },
        });
      } catch {
        //
      }
      pageSaveItem('isFirstQrcode', 1);
      if (!isCanDownloadBrowser()) {
        showOpenOthetBrowserTips();
        return;
      }
    }
    x.dispatch('request', {
      code,
      agentId,
    });
  });

  const handleDownload = (tag, link, _blank) => {
    const { log } = getDownloadContent(tag);
    operateLog(log, '', {});
    // 检测下载链接是否可用, 不可用则使用本地下载链接
    const href = link || x.state.data[tag].link;
    x.dispatch('showFaqs', { tag, link: href });
    if (_blank) {
      const a = document.createElement('a');
      a.href = href;
      a.target = '_blank';
      a.click();
      return;
    }
    window.location.href = href;
  };
  // 非ios1, ios2, android2下载特殊处理
  const handleDownloadLocalLink = async (tag, link) => {
    // 检测下载链接是否可用, 不可用则使用本地下载链接
    let href = link || x.state.data[tag].link;
    const localHref = href.replace(/^http(s)?:\/\/(.*?)\//, `${window.location.origin}/`);
    const stop = showLoading();
    try {
      const response = await axios.head(href, { timeout: 3000, timeoutErrorMessage: 'timeout' });
      if (response.status >= 400) href = localHref;
    } catch (error) {
      // if (error.message === 'timeout') href = localHref;
      href = localHref;
    }
    handleDownload(tag, href);
    stop();
  };
  // ios1下载特殊处理
  const handleDownloadiOS1 = async tag => {
    if (!x.state.data[tag].link) {
      const res = await showConfirm(
        content.value.text20,
        content.value.text22,
        content.value.text23,
      );
      if (res === 'confirm') {
        store.dispatch('signOut');
        store.dispatch('changeWantDownload', true);
        router.push('/login');
      }
      return;
    }
    handleDownload(tag, null, x.getters.isSuperSign);
  };
  // mac下载特殊处理
  const handleDownloadMac = tag => {
    const e = window.event;
    if (e.target !== e.currentTarget) return;
    const propsData = {
      selectList: ['Intel 处理器', 'M1 处理器'],
      top: `${e.pageY}px`,
      left: `${e.pageX}px`,
      onClick: index => {
        index === 1
          ? handleDownloadLocalLink(tag, x.state.data[tag].link_arm)
          : handleDownloadLocalLink(tag);
      },
    };
    showSelectList(propsData);
  };
  const download = tag => {
    // if (!x.getters.isMacInstaller && tag.startsWith('mac')) {
    //   handleDownloadMac(tag);
    //   return;
    // }
    if (tag === 'ios1') {
      handleDownloadiOS1(tag);
      return;
    }
    if (['ios2', 'android2', 'ubuntu2'].includes(tag)) {
      handleDownload(tag);
      return;
    }
    handleDownloadLocalLink(tag);
  };

  const goAppStoreHelp = () => {
    operateLog('APPSTORE_INSTRUCTION', '', {});
    openNewPage(`/${isMd.value ? 'pc' : 'm'}/appstore_help/index.html`);
  };

  const autoLogin = useAutoLogin();
  const switchActive = ref(1);
  onMounted(() => {
    autoLogin();
    switchActive.value = getSwitchActive(route.params);
  });

  return {
    content,
    isMd,
    headerType: useHeaderType(2, 1, 2),
    switchActive,
    x,
    download,
    goAppStoreHelp,
  };
};
