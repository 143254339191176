<script>
import {
  computed,
  toRef,
  ref,
} from '@vue/composition-api';
import { getProjectConfig } from '@/utils/utils';

function useSwitchMenus(switchActive, hoverActive) {
  const imgSrcBase = `${getProjectConfig().imgSrcBase}/imgs/download`;
  const y = [
    // `${imgSrcBase}/download_ios_2.svg`,
    `${imgSrcBase}/download_android_2.svg`,
    `${imgSrcBase}/download_windows_2.svg`,
    `${imgSrcBase}/download_mac_2.svg`,
    `${imgSrcBase}/download_linux_2.svg`,
  ];
  return computed(() => {
    const menus = [
      // { name: 'iPhone/iPad', src: `${imgSrcBase}/download_ios_1.svg` },
      { name: 'Android', src: `${imgSrcBase}/download_android_1.svg` },
      { name: 'Windows', src: `${imgSrcBase}/download_windows_1.svg` },
      { name: 'Mac', src: `${imgSrcBase}/download_mac_1.svg` },
      { name: 'Linux', src: `${imgSrcBase}/download_linux_1.svg` },
    ];
    const i = switchActive.value - 1;
    i >= 0 && (menus[i].src = y[i]);
    const j = hoverActive.value - 1;
    j >= 0 && (menus[j].src = y[j]);
    return menus;
  });
}

export default {
  model: {
    props: 'value',
    event: 'change',
  },
  props: {
    value: { type: Number, required: true },
  },
  emits: ['change'],
  setup(props) {
    const hoverActive = ref(0);
    const switchMenus = useSwitchMenus(toRef(props, 'value'), hoverActive);
    return { hoverActive, switchMenus };
  },
  render() {
    const {
      value,
      switchMenus,
      $attrs,
    } = this;
    const { css } = $attrs;
    return (
      <div class={css['switch-menu']}>
        {switchMenus.map((item, index) => (
          <div
            key={item.name}
            class={css['menu-item']}
            onMouseenter={() => { this.hoverActive = index + 1; }}
            onMouseleave={() => { this.hoverActive = 0; }}
            onClick={() => this.$emit('change', index + 1)}
          >
            <img class={css.img} src={item.src} />
            <div class={[css.text, { [css.active]: value === index + 1 }]}>
              {item.name}
            </div>
          </div>
        ))}
      </div>
    );
  },
};
</script>
